<!--
This view displays information of activity of fortnite map data over time.
the firestore is storing map data under /ScrapFNSnapshots each object key is a timestamp
each object contains 
"maps": [
          {
            "islandCode": "1760-0152-1306",
            "name": "TILTED ZONE WARS (ALL WEAPONS) ⭐",
            "author": "PrettyBoyyAM",
            "image": "https://cdn-0001.qstv.on.epicgames.com/HCEqtcwuoiAFNdODTG/image/screen_comp.jpeg",
            "playerCount": 5576
        },
        ...
]
It displays a graph of the number of players on each map over time.
It uses D3LineChart from vue-d3-charts library to display the graph.
It uses the db.collection to get the data from the firestore.
Let the user pick the starting and end date.
if a map is in mapColors, add a small button to open a modal with the map card info.
Add a searchbox to filter maps by name, author and tags.
-->

<template>
  <div>
    <div v-if="!chartData">
      <div class="d-flex justify-content-center align-items-center" style="height:300px">
        <b-spinner label="Loading..." variant="primary" />
      </div>
    </div>
    <D3LineChart style="height:300px" v-if="chartData" :datum="chartData" :config="chartOptions" />
    <div class="container">
      <div class="row">
        <label for="selector" class="col-4">Select:</label>
        <b-form-select id="selector" class="col" v-model="selectedOption" size="sm" @change="updateChart">
          <option value="all">All</option>
          <option value="numbered">Community</option>
        </b-form-select>
      </div>
      <div class="row">
        <label for="startDate" class="col-4">Start Date:</label>
        <b-form-datepicker id="startDate" class="col" value-as-date size="sm" v-model="startDate" :max="endDate"
          @input="updateChartRange" />
      </div>
      <div class="row">
        <label for="endDate" class="col-4">End Date:</label>
        <b-form-datepicker id="endDate" class="col" value-as-date size="sm" v-model="endDate" :min="startDate"
          @input="updateChartRange" />
      </div>
      <div class="row">
        <label for="search" class="col-4">Search:</label>
        <b-input-group size="sm" class="col p-0">
          <b-form-input id="search"  v-model="search" />
          <b-input-group-append>
            <b-button variant="outline-secondary" @click="search = ''">
              <font-awesome-icon icon="times" />
            </b-button>
          </b-input-group-append>
          <b-input-group-append>
            <b-button size="sm" @click="selectAll">Select</b-button>
          </b-input-group-append>
          <b-input-group-append>
            <b-button variant="outline-secondary" @click="selectedMaps = [];updateChart();">
              <font-awesome-icon icon="times" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
    <div>
      <div class="d-flex flex-wrap">
        <div v-for="map in filteredMaps" :key="map.islandCode + mapColors[map.islandCode]"
          style="width: 200px;white-space: nowrap; max-width: 200px; overflow: hidden"
          :style="`background-color: ${mapColors[map.islandCode]}`">
          <input class="m-1" type="checkbox" :id="map.islandCode" :value="map.islandCode" v-model="selectedMaps"
            @change="updateChart">
          <label :for="map.islandCode" class="d-inline-block"
            :style="map.islandCode in mapColors ? 'width: 140px; max-width: 140px; vertical-align: bottom; overflow: hidden' : ''">{{
              map.name }}</label>
          <b-button v-if="map.islandCode in mapColors" variant="primary" size="sm" @click="openMapCard(map)"
            class="d-inline-block ml-1">
            <font-awesome-icon icon="eye" />
          </b-button>
        </div>
      </div>
    </div>
    <b-modal id="modal-preview" v-if="selectedMap" centered ok-only :title="selectedMap.name" @hide="selectedMap = null">
      <div class="d-flex flex-column align-items-center">
        <map-card :map="selectedMap" details />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { getLog } from '@/services/log'
const log = getLog('charts');
import { db } from '@/services/db'
import { D3LineChart } from 'vue-d3-charts'

export default {
  name: 'TestFnChart',
  components: {
    D3LineChart,
    MapCard : () => import('./components/MapCard.vue')
  },
  data() {
    return {
      chartData: null,
      chartOptions: null,
      selectedOption: 'numbered',
      maps: [],
      selectedMaps: [],
      mapColors: {},
      snapshots: [],
      showModal: false,
      selectedMap: null,
      search: '',
    }
  },
  computed: {
    filteredMaps() {
      return this.maps.filter(map => {
        return map.name.toLowerCase().includes(this.search.toLowerCase())
          || map.author.toLowerCase().includes(this.search.toLowerCase())
          || map.islandCode.toLowerCase().includes(this.search.toLowerCase())
          || map.tags.some(tag => tag.toLowerCase().includes(this.search.toLowerCase()))
      });
    }
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    this.chartData = [];
    this.maps = [];
  },
  methods: {
    selectAll() {
      log.log('selectAll');
      this.selectedMaps = this.filteredMaps.map(map => map.islandCode);
      log.log('selectedMaps', this.selectedMaps);
      this.updateChart();
    },
    async init() {
      // set enddate as now and startdate as 1 week ago
      this.endDate = new Date();
      this.startDate = new Date();
      this.startDate.setDate(this.endDate.getDate() - 7);
      await this.updateChartRange();
    },
    async fetchSnapshot() {
      this.snapshots = [];
      this.maps = [];
      let querySnapshot = await db.collection('ScrapFNSnapshots')
        .where('timestamp', '>=', this.startDate)
        .where('timestamp', '<=', this.endDate)
        .get();
      let snapshots = [];
      let maps = {};
      querySnapshot.forEach(doc => {
        const obj = doc.data();
        snapshots.push(obj);
        obj.maps.forEach(map => {
          maps[map.islandCode] = map;
        })
      })
      this.maps = Object.values(maps).sort((a, b) => b.playerCount - a.playerCount);
      this.snapshots = snapshots;
      // log.log('snapshots', snapshots);
    },
    async updateChartRange() {
      log.log('updateChartRange start:', this.startDate, "end:", this.endDate);
      this.chartData = null;
      await this.fetchSnapshot();
      this.$nextTick(() => {
        this.prepareChartData();
      });
    },
    updateChart() {
      log.log('updateChart');
      this.chartData = null;
      this.$nextTick(() => {
        this.prepareChartData();
      })
    },
    prepareChartData() {
      const chartData = []
      let values = {};
      this.snapshots.forEach(obj => {
        const data = {};
        // log.log('data', data);
        obj.maps.forEach(map => {
          if (this.selectedOption === 'numbered' && !/^\d/.test(map.islandCode)) {
            return;
          }
          if (this.selectedMaps.length > 0 && !this.selectedMaps.includes(map.islandCode)) {
            return;
          }
          if (Object.keys(data).length < 10) {
            data[map.islandCode] = map.playerCount || 0;
            values[map.islandCode] = true;
          }
        })
        data.date = obj.date;
        // log.log('data', data);
        chartData.push(data);
      })
      // log.log('chartData', chartData);
      values = Object.keys(values);
      // normalize values in chart data
      chartData.forEach(data => {
        values.forEach(value => {
          if (!data[value]) {
            data[value] = 0;
          }
        })
      })
      // Add more colors
      const colors = [
        '#f44336',
        '#e81e63',
        '#9c27b0',
        '#673ab7',
        '#3f51b5',
        '#2196f3',
        '#03a9f4',
        '#00bcd4',
        '#009688',
        '#4caf50',
        '#8bc34a',
        '#cddc39',
        '#ffeb3b',
        '#ffc107',
        '#ff9800',
        '#ff5722',
        '#795548',
        '#9e9e9e',
        '#607d8b',
        '#DeDeDe',];

      // Create an object to store the color for each map
      const mapColors = {};
      // Loop through the values array and assign a color to each map
      values.forEach((map, index) => {
        // If we run out of colors, start over from the beginning
        const colorIndex = index % colors.length;
        mapColors[map] = colors[colorIndex];
      });
      // log.log('mapColors', mapColors);
      // Set the mapColors data property to the mapColors object we just created
      this.mapColors = mapColors;
      this.chartData = chartData;
      this.chartOptions = {
        values,
        date: {
          key: 'date',
          inputFormat: '%Y-%m-%dT%H:%M:%S',
          outputFormat: '%Y-%m-%dT%H:%M:%S',
        },
        axis: {
          yTicks: 3,
        },
        color: {
          scheme: colors,
        },
      };
      // log.log('this.chartOptions', this.chartOptions);
    },
    openMapCard(map) {
      log.log('openMapCard', map);
      this.selectedMap = map;
      this.$nextTick(() => {
        this.$bvModal.show('modal-preview');
      })
    },
  }
}
</script>

<style scoped></style>



